/**
 * Use this to access browser globals in an server-side-rendering-safe way
 *
 * @returns The globals if in a browser environment, or undefined if in a
 * SSR environment
 */

export default function getBrowserGlobals() {
  // eslint-disable-next-line no-restricted-globals
  return typeof window === 'undefined'
    ? undefined
    : // eslint-disable-next-line no-restricted-globals
      { window, document, HTMLElement };
}
