import React from 'react';
import PropTypes from 'prop-types';

import { useBreakpoint } from 'react-use-size';
import { screenXsMin } from 'shared/constants/breakpoints';

import ResponsiveModal from 'components/common/ResponsiveModal/ResponsiveModal';
import CouponDetails from 'components/registration/CouponDetails/CouponDetails';
import styles from './CouponModal.module.scss';

const CouponModal = ({ setShowCouponModal, learnMore, coupon }) => {
  const scrollerRef = React.useRef();
  const isMobile = useBreakpoint(screenXsMin);

  const scrollerRefCallback = (node) => {
    if (node) {
      scrollerRef.current = node;
    }
  };

  const onCloseModal = () => {
    setShowCouponModal(false);
  };

  if (!learnMore) {
    return null;
  }

  return (
    <ResponsiveModal
      isMobile={isMobile}
      onRequestClose={onCloseModal}
      contentLabel="Coupon Modal"
      contentClassName={styles.container}
      scrollerRefCallback={scrollerRefCallback}
      isDynamicSizing
    >
      <div className={styles.contentWrapper}>
        <CouponDetails coupon={coupon} />
      </div>
    </ResponsiveModal>
  );
};

CouponModal.propTypes = {
  setShowCouponModal: PropTypes.func.isRequired,
  learnMore: PropTypes.bool.isRequired,
  coupon: PropTypes.shape().isRequired
};

export default CouponModal;
