import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const WingedEnvelope = ({ height, width }) => {
  return (
    <Icon height={height} width={width}>
      <defs>
        <path id="a" d="M0 0h30v25H0z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(0 9)">
          <path
            fill="#1EB392"
            d="m17.763 13.16-1.558-3.258 10.132-3.62 1.559 3.258-10.133 3.62zm8.149 6.12L15.886 22.9v-9.595l.637 1.376c.142.326.46.507.78.507.106 0 .212 0 .283-.036l8.326-2.969v7.096zM4.088 12.146l8.326 2.969a.766.766 0 0 0 .283.036.848.848 0 0 0 .78-.507l.637-1.376v9.559l-10.026-3.62v-7.061zm-.425-5.902 10.132 3.621-1.558 3.258-10.133-3.62 1.559-3.259zM15 1.865l9.14 3.294L15 8.418 5.86 5.123 15 1.865zm14.915 7.784L27.612 4.76c-.106-.217-.283-.362-.496-.47L15.283.053a.767.767 0 0 0-.567 0L2.92 4.29c-.213.073-.39.254-.496.471L.085 9.65a1.023 1.023 0 0 0-.036.724c.107.253.284.434.532.507l1.736.615v8.4c0 .398.248.724.602.832l11.797 4.237A.764.764 0 0 0 15 25c.106 0 .212 0 .283-.036l11.798-4.237c.354-.144.602-.47.602-.832v-8.363l1.736-.616c.248-.072.426-.253.531-.507a1.12 1.12 0 0 0-.035-.76z"
            mask="url(#b)"
          />
        </g>
        <path
          fill="#1EB392"
          d="M15 6c.56 0 1-.398 1-.904V.904C16 .398 15.56 0 15 0s-1 .398-1 .904v4.229c0 .47.44.867 1 .867m5.413.866a.83.83 0 0 0 .468.134.901.901 0 0 0 .755-.403l2.23-3.329c.252-.403.144-.908-.288-1.143-.431-.235-.97-.134-1.223.269l-2.23 3.328c-.251.37-.108.908.288 1.144M8.359 6.597A.9.9 0 0 0 9.113 7a.828.828 0 0 0 .466-.134.797.797 0 0 0 .288-1.144L7.64 2.394c-.251-.403-.826-.504-1.22-.269a.797.797 0 0 0-.288 1.143L8.36 6.597z"
        />
      </g>
    </Icon>
  );
};

WingedEnvelope.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string
};

WingedEnvelope.defaultProps = {
  height: '34',
  width: '30'
};

export default WingedEnvelope;
