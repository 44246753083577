import React, { useEffect } from 'react';
import PropTypes, { bool, func, string } from 'prop-types';
import cx from 'classnames';

import CloseButton from 'components/common/CloseButton';
import ModalWrapper from 'components/common/ModalWrapper';
import { enableBodyScroll, disableBodyScroll } from 'shared/utils/body-scroll-utils';
import { isSafariMobile } from 'shared/utils/detect-mobile';

import styles from './ResponsiveModal.module.scss';

const modalStyles = {
  content: {
    overflow: 'hidden'
  }
};

const modalMobileStyles = {
  content: {
    overflow: 'hidden auto',
    margin: 0
  }
};

/**
 * React modal that adjusts layout and styles dependent on the resizing of content and manages
 * main window scrolling (disable/re-enable)
 * Also, manages fixing of Safari scrolling issues for mobile
 */
const ResponsiveModal = ({
  isMobile,
  children,
  onRequestClose,
  contentLabel,
  contentClassName,
  scrollerRefCallback,
  isDynamicSizing,
  isReadOnly
}) => {
  useEffect(() => {
    disableBodyScroll();
    return () => enableBodyScroll();
  }, []);

  const scrollerRef = React.useRef();

  let lastY = 0;
  const handleTouchStart = (event) => {
    lastY = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    const top = event.touches[0].clientY;
    const scroller = event.currentTarget;
    const { scrollTop } = scroller;
    const isDown = lastY - top < 0;
    if ((isDown && scrollTop <= 1) || (!isDown && scrollTop >= scroller.scrollHeight - scroller.offsetHeight - 1)) {
      // Prevent scrolling up when already at top as this introduces a freeze.
      // Prevent scrolling down when already at bottom as this also introduces a freeze.
      // Prevent scrolling of parent (bubbling) after we prevented default for child scroller
      event.preventDefault();
      event.stopPropagation();
    }
    lastY = top;
  };

  const handleScroll = (event) => {
    // prevent scrolling to edges so it will not freeze
    const scroller = event.currentTarget;
    scroller.scrollTop = Math.max(1, Math.min(scroller.scrollTop, scroller.scrollHeight - scroller.clientHeight - 1));
  };

  const contentRefCallback = (node) => {
    if (node) {
      scrollerRef.current = node;
      scrollerRefCallback(node);
    }
  };

  useEffect(() => {
    if (scrollerRef.current && isMobile && isSafariMobile()) {
      const element = scrollerRef.current;
      element.addEventListener('touchstart', handleTouchStart);
      element.addEventListener('touchmove', handleTouchMove);
      element.addEventListener('scroll', handleScroll);

      return () => {
        if (element.removeEventListener) {
          element.removeEventListener('touchstart', handleTouchStart);
          element.removeEventListener('touchmove', handleTouchMove);
          element.removeEventListener('scroll', handleScroll);
        }
      };
    }
    return undefined;
  }, [isMobile, scrollerRef.current]);

  return (
    <ModalWrapper
      isOpen
      style={isMobile ? modalMobileStyles : modalStyles}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      contentRef={contentRefCallback}
      className={cx(styles.modalStyles, { [styles.dynamicSizingModal]: isDynamicSizing, [styles.small]: isReadOnly })}
    >
      <div
        className={cx(contentClassName, styles.container, {
          [styles.dynamicSizingContainer]: isDynamicSizing,
          [styles.small]: isReadOnly
        })}
      >
        {children}
        <CloseButton onClose={onRequestClose} fill="#6a6d75" width="13" height="13" isRounded />
      </div>
    </ModalWrapper>
  );
};

ResponsiveModal.propTypes = {
  isMobile: bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onRequestClose: func,
  contentLabel: string,
  contentClassName: string,
  scrollerRefCallback: func,
  isDynamicSizing: bool,
  isReadOnly: bool
};

ResponsiveModal.defaultProps = {
  contentLabel: undefined,
  contentClassName: undefined,
  isMobile: false,
  onRequestClose: () => {},
  scrollerRefCallback: () => {},
  isDynamicSizing: false,
  isReadOnly: false
};

export default ResponsiveModal;
