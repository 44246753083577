import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setOptimizelyExperiments } from 'redux/reducers/configurations';

export class OptimizelyExperiments extends React.Component {
  componentDidMount() {
    this.updateExperiments();
  }

  componentDidUpdate() {
    this.updateExperiments();
  }

  updateExperiments() {
    const experimentsMap =
      window.optimizely &&
      typeof window.optimizely.get === 'function' &&
      window.optimizely.get('state').getExperimentStates();
    const experiments = Object.values(experimentsMap || {}).reduce((acc, { experimentName, variation }) => {
      if (!experimentName || !variation) return acc;
      return [...acc, `${experimentName} ${variation.name}`];
    }, []);

    this.props.dispatch(setOptimizelyExperiments(experiments));
  }

  render() {
    return null;
  }
}

OptimizelyExperiments.propTypes = {
  dispatch: PropTypes.func.isRequired
};

export const ConnectedOptimizelyExperiments = connect()(OptimizelyExperiments);

export default ConnectedOptimizelyExperiments;
