import React from 'react';
import PropTypes from 'prop-types';
import CouponBox from 'components/common/icons/CouponBox/CouponBox';
import styles from './CouponDetails.module.scss';

const CouponDetails = ({ coupon }) => {
  const { maxDrips, legalCopy, couponMessage } = coupon;
  return (
    <div className={styles.couponDetails} data-testid="CouponDetails">
      <div className={styles.dripContainer}>
        {maxDrips.map((drip) => {
          const { amount, shippingAmount } = drip;
          return (
            <div key={drip} className={styles.couponContainer}>
              <CouponBox />
              {amount && amount.dollars && !amount.percent && (
                <span className={styles.couponAmount}>{`$${amount.dollars} Off ${shippingAmount ? '+' : ''}`}</span>
              )}
              {amount && !!amount.percent && (
                <span className={styles.couponAmount}>{`${amount.percent} Off ${shippingAmount ? '+' : ''}`}</span>
              )}
              {shippingAmount && <span className={styles.couponAmount}>Free Shipping</span>}
            </div>
          );
        })}
      </div>
      {couponMessage && <span className={styles.promoText}>{couponMessage}</span>}
      {legalCopy && <p className={styles.couponLegalCopy}>{legalCopy}</p>}
    </div>
  );
};

CouponDetails.propTypes = {
  coupon: PropTypes.shape({
    code: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired,
    couponMessage: PropTypes.string.isRequired,
    maxAmountPerUse: PropTypes.string,
    prices: PropTypes.arrayOf(
      PropTypes.shape({
        planId: PropTypes.string.isRequired,
        productsPerOrder: PropTypes.string.isRequired,
        introductory: PropTypes.arrayOf(PropTypes.string).isRequired
      })
    ),
    restrictions: PropTypes.shape({
      card: PropTypes.bool
    }),
    maxDrips: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.shape({
          dollars: PropTypes.string
        }),
        shippingAmount: PropTypes.shape()
      })
    ).isRequired,
    legalCopy: PropTypes.string.isRequired
  })
};

CouponDetails.defaultProps = {
  coupon: null
};
export default CouponDetails;
