import $ from 'jquery';
import { isNumber } from '../fetch/utils';

const HEADER_HEIGHT = 67;

export const storeBodyScroll = () => {
  const htmlElement = $('body').get(0).parentElement;
  window.lastStoredBodyScroll = htmlElement.scrollTop;
};

export const enableBodyScroll = () => {
  $('body').removeClass('stopscroll').unbind('touchstart').unbind('touchmove');
  $(document).unbind('touchmove');
  if (isNumber(window.lastStoredBodyScroll)) {
    // if we have stored scroll position, restore it
    setTimeout(() => {
      $('body').get(0).parentElement.scrollTo({ top: window.lastStoredBodyScroll });
      window.lastStoredBodyScroll = NaN;
    }, 10);
  }
};

export const enableBodyWrapScroll = (scrollToTop = true) => {
  const $bodywrap = $('#body-wrap-container');
  if ($bodywrap.offset()) {
    $bodywrap.removeClass('fixed');
    const top = $bodywrap.offset().top * -1;
    $bodywrap.css({ top: '' });
    $bodywrap.css({ width: '' });

    if (scrollToTop) {
      $(window).scrollTop(top);
    }
  } else {
    enableBodyScroll();
  }
};

export const disableBodyScroll = (targetClass = '.fancybox-inner') => {
  const $body = $('body');
  $body.addClass('stopscroll');
  const selScrollable = targetClass;
  $(document).on('touchmove', (e) => {
    e.preventDefault();
  });
  $body.on('touchstart', selScrollable, (e) => {
    if (e.currentTarget.scrollTop === 0) {
      e.currentTarget.scrollTop = 1;
    } else if (e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.offsetHeight) {
      e.currentTarget.scrollTop -= 1;
    }
  });
  $body.on('touchmove', selScrollable, (e) => {
    if ($(this)[0].scrollHeight > $(this).innerHeight()) {
      e.stopPropagation();
    }
  });
};

export const disableBodyWrapScroll = (offsetTop) => {
  const $bodywrap = $('#body-wrap-container');
  if ($bodywrap.offset()) {
    $bodywrap.addClass('fixed');
    const width = $('body').width();

    $bodywrap.css({ top: `-${offsetTop}px` });
    $bodywrap.css({ width: `${width}px` });
  } else {
    disableBodyScroll();
  }
};

export const scrollToElement = (element, disableScroll = false, container = 'html, body') => {
  // do not need an offset on table because the header is not fixed.
  // on desktop header is fixed with a 80px width
  const TABLET_OFFSET = 0;
  const DESKTOP_OFFSET = HEADER_HEIGHT;
  const baseOffset = window.innerWidth >= 960 ? DESKTOP_OFFSET : TABLET_OFFSET;
  const offset = $(element).offset() && $(element).offset().top;

  $(container).animate({ scrollTop: offset - baseOffset }, 500);

  if (disableScroll) {
    if (window.innerWidth < 768) {
      setTimeout(disableBodyWrapScroll(offset), 500);
    } else {
      setTimeout(disableBodyScroll(), 500);
    }
  }
};

export const enableScrolling = () => {
  if (window.innerWidth < 768) {
    return enableBodyWrapScroll();
  }
  return enableBodyScroll();
};
