import MobileDetect from 'mobile-detect';

export const mobileDetect = (device) => {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.match(device);
};

export const isSafariMobile = () =>
  mobileDetect('iphone|ipod|ipad') &&
  window.navigator.vendor &&
  window.navigator.vendor.indexOf('Apple') > -1 &&
  window.navigator.userAgent &&
  !mobileDetect('CriOS|FxiOS');
