import React from 'react';
import PropTypes from 'prop-types';
import reportToRollbar from 'shared/utils/error-handling/report-to-rollbar/report-to-rollbar';

export default class ErrorBoundary extends React.Component {
  state = { error: null };

  componentDidCatch(error) {
    this.setState({ error });
    if (this.props.reportToRollbar) reportToRollbar(error);
  }

  render() {
    if (this.state.error && !this.props.alwaysRenderChildren) {
      return this.props.errorRenderer ? this.props.errorRenderer(this.state.error) : <span data-id="error-boundary" />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  reportToRollbar: PropTypes.bool,
  errorRenderer: PropTypes.func,
  /*
    Do not use this prop. Fix the error.

    If you **do** use this prop:
    https://www.youtube.com/watch?v=aYmOqPFyJPw
  */
  alwaysRenderChildren: PropTypes.bool
};

ErrorBoundary.defaultProps = {
  reportToRollbar: true,
  errorRenderer: undefined,
  alwaysRenderChildren: false
};
