import React from 'react';
import { shape, bool, element } from 'prop-types';
import Modal from 'react-modal';
import getBrowserGlobals from 'shared/utils/globals';
import modalStyles from './constants';

const ModalWrapper = ({ style, isOpen, children, ...props }) => {
  const styles = {
    ...modalStyles,
    overlay: { ...modalStyles.overlay, ...style.overlay },
    content: { ...modalStyles.content, ...style.content }
  };
  const globals = getBrowserGlobals();
  return (
    <Modal
      {...props}
      style={styles}
      isOpen={isOpen}
      appElement={globals && globals.document.getElementById('body-wrap')}
    >
      {children}
    </Modal>
  );
};

ModalWrapper.propTypes = {
  style: shape({}),
  isOpen: bool,
  children: element.isRequired
};

ModalWrapper.defaultProps = {
  style: {},
  isOpen: false
};

export default ModalWrapper;
