export default {
  overlay: {
    position: 'fixed',
    zIndex: 2000,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    margin: '0 auto',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'relative',
    borderRadius: '0',
    overflow: 'visible',
    padding: 0,
    border: 0,
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, .1)'
  }
};
